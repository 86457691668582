export const data = [
    // Thông tin chung
    {
        key: 'none',
        title: 'Thông tin chung',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'size_van_phong',
        title: 'Size văn phòng'
    },
    {
        key: 'luong_co_ban',
        title: 'Lương cơ bản (1)',
        prefix: 'currency',
    },
    {
        key: 'co_phan',
        title: `Cổ phần (2)`,
        bold: true,
        prefix: '%%',

    },
    //Các chỉ tiêu tháng
    {
        key: 'none',
        title: 'Các chỉ tiêu tháng',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'chi_tieu_doanh_so',
        title: 'Chỉ tiêu doanh số (3)',
        prefix: 'currency',
    },
    {
        key: 'chi_tieu_cong_di_lam',
        title: 'Chỉ tiêu công đi làm (4)',
    },
    // Kết quả đạt được trong tháng
    {
        key: 'none',
        title: 'Kết quả đạt được trong tháng',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'doanh_so_doi_van_phong',
        title: 'Doanh số đội văn phòng (5)',
        prefix: 'currency',

    },
    {
        key: 'doanh_so_ca_nhan',
        title: 'Doanh số cá nhân (5.1)',
        prefix: 'currency',
        tooltip: "Đã nằm trong mục (5)"
    },
    {
        key: 'doanh_so_doi_gdvp',
        title: 'Doanh số đội GĐVP (5.2)',
        prefix: 'currency',
        tooltip: "Đã nằm trong mục (5)"
    },
    {
        key: 'cong_di_lam',
        title: 'Công đi làm (6)'
    },
    {
        key: 'ds_digital_ca_nhan',
        title: 'Doanh số Digital cá nhân',
        prefix: 'currency',
        tooltip: ''
    },
    {
        key: 'ds_digital_doi',
        title: 'Doanh số Digital đội',
        prefix: 'currency',
        tooltip: ''
    },
    {
        key: 'kpi_doanh_so',
        title: 'KPI Doanh số (8) = (5) / (3) * 100%',
        prefix: '%%'
    },
    {
        key: 'kpi_cong_di_lam',
        title: 'KPI Công đi làm (9) = (6) / (4) * 100%',
        prefix: '%%'
    },
    {
        key: 'diem_wow',
        title: 'Điểm WOW (10)',
    },
    {
        key: 'kpi_luong_cung',
        title: 'KPI lương cứng (11) = (9) * 50% + (10) * 50%',
        prefix: '%%'
    },
    // 4. Các khoản chi
    {
        key: 'none',
        title: 'Các khoản chi',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'chi_luong_kinh_doanh',
        title: 'Chi lương kinh doanh (12)',
        tooltip: 'Tổng lương của nhân sự kinh doanh và Lương cứng + Thưởng của GĐVP',
        prefix: 'currency'

    },
    {
        key: 'chi_phi_san_pham',
        title: 'Chi phí sản phẩm (13)',
        prefix: 'currency'
    },
    {
        key: 'chi_nha',
        title: 'Chi nhà (14)',
        prefix: 'currency'
    },
    {
        key: 'chi_khau_hao',
        title: 'Chi khấu hao (15)',
        prefix: 'currency'
    },

    {
        key: 'chi_phi_back_office',
        title: 'Chi phí back office (16)',
        bold: true,
        tooltip: '12,5% doanh số',
        prefix: 'currency'
    },
    {
        key: 'chi_phuc_loi',
        prefix: 'currency',
        title: 'Chi phúc lợi (17)',

    },
    {
        key: 'chi_luong_hckv',
        prefix: 'currency',
        title: 'Chi lương HCKV (18)',

    },
    {
        key: 'chi_van_phong',
        prefix: 'currency',
        title: 'Chi văn phòng (19)',

    },
    {
        key: 'chi_khac',
        prefix: 'currency',
        title: 'Chi khác (20)',
    },
    {
        key: 'tong_cac_khoan_chi',
        prefix: 'currency',
        title: 'Tổng các khoản chi (21)',
    },

    // 5. Các chỉ số kinh doanh
    {
        key: 'none',
        title: 'Các chỉ số kinh doanh',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'doanh_so_tai_diem',
        prefix: 'currency',
        title: 'Doanh số tại điểm (22)',

    },
    {
        key: 'phan_tram_chi_san_pham',
        title: '% chi sản phẩm (23)',
        prefix: '%%'
    },
    {
        key: 'loi_nhuan_kinh_doanh',
        title: 'Lợi nhuận kinh doanh (24) = (5) - (21)',
        prefix: 'currency',
        tooltip: '(24) = (5) - (21)'
    },
    {
        key: '50_phan_tram_loi_nhuan_chia_trong_thang',
        title: '50% lợi nhuận chia trong tháng (25) = (24) * 50%',
        prefix: 'currency',
        tooltip: '(25) = (24) * 50%'
    },
    {
        key: 'thuong_trai_phieu_tich_luy',
        title: 'Giá trị trái phiếu tích lũy',
        prefix: "currency"
    },
    //6. Các khoản thu nhập
    {
        key: 'none',
        title: 'Các khoản thu nhập',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'thu_nhap_theo_luong_cung',
        prefix: 'currency',
        title: 'Thu nhập theo lương cứng (26)',
        tooltip: '(26) = (1) * (11)'
    },
    {
        key: 'phan_tram_thuong_kpi',
        title: '% Thưởng KPI (27)',
        tooltip: "Đạt chỉ tiêu: Thưởng % KPIs theo bậc\n" +
            "Đạt từ 70-100% chỉ tiêu: Nhận 50% thưởng KPIs\n" +
            "Đạt dưới 70% chỉ tiêu: Không nhận thưởng\n",
        prefix: '%%',
    },
    {
        key: 'thuong_kpi',
        title: 'Thưởng KPI (28)',
        prefix: 'currency',

    },
    {
        key: 'phan_tram_thuong_ds_digital_ca_nhan',
        title: '% Thưởng doanh số digital cá nhân',
        prefix: 'currency',

    },
    {
        key: 'thuong_ds_digital_ca_nhan',
        title: 'Thưởng doanh số digital cá nhân',
        prefix: 'currency',

    },
    {
        key: 'phan_tram_thuong_ds_digital_doi',
        title: '% Thưởng doanh số digital đội',
        prefix: 'currency',

    },
    {
        key: 'thuong_ds_digital_doi',
        title: 'Thưởng doanh số digital đội',
        prefix: 'currency',

    },
    {
        key: 'thuong_ds_ca_nhan',
        title: 'Thưởng DS cá nhân (29.1)',
        prefix: 'currency',
    },
    {
        key: 'thuong_ds_doi_gdvp',
        title: 'Thưởng DS đội GĐVP (29.2)',
        prefix: 'currency',
    },
    {
        key: 'thuong_dao_tao',
        title: 'Thưởng đào tạo (30)',
        prefix: 'currency',
    },
    {
        key: 'thuong_phong_van',
        title: 'Thưởng phỏng vấn (31)',
        prefix: 'currency',
    },
    {
        key: 'loi_tuc',
        title: 'Lợi tức (32)',
        tooltip: "(32) = (2) * (25)",
        prefix: 'currency',
    },
    {
        key: 'phu_cap',
        title: 'Phụ cấp (33)',
        prefix: 'currency',

    },
    {
        key: 'thu_nhap_thieu_thang_truoc',
        title: 'Thu nhập thiếu tháng trước (34)',
        prefix: 'currency',

    },
    {
        key: 'luong_cung_thuong_kpi_loi_tuc',
        title: ' Lương cứng + Thưởng KPI + Lợi tức (35)',
        tooltip: "(35) = (26) + (28) + (32)",
        prefix: 'currency',
    },
    {
        key: 'luong_cung_thuong_kpi_loi_tuc_thuc_tra',
        title: 'Lương cứng + Thưởng KPI + Lợi tức thực trả (36)',
        tooltip: "- Nếu (35) >= 5.000.000 VNĐ thì (36) = (35)\n" +
            "- Nếu (35) < 5.000.000 VNĐ thì (36) = 5.000.000 VNĐ",
        prefix: 'currency',
    },
    {
        key: 'tong_cac_khoan_thu_nhap',
        title: 'Tổng các khoản thu nhập (37)',
        tooltip: "(37) = (36) + (28) + (29.1) + (29.2) + (29.3) + (30) + (31) + (33) + (34)",
        prefix: 'currency',
    },

    // 7. Các khoản trừ
    {
        key: 'none',
        title: 'Các khoản trừ',
        title_class_name: 'bold text-sm-h5',
    },
    {
        key: 'trich_dong_bhxh',
        title: 'Trích đóng BHXH (38)',
        tooltip: "Áp dụng với GĐVP từ 23 tuổi trở lên",
        prefix: 'currency',
    },
    {
        key: 'cong_no',
        title: 'Công nợ (39)',
        prefix: 'currency',

    },
    {
        key: 'von_gop',
        title: 'Vốn góp (40)',
        prefix: 'currency',

    },
    {
        key: 'phat',
        title: 'Phạt (41)',
        prefix: 'currency',

    },
    {
        key: 'tru_khac',
        title: 'Trừ khác (42)',
        prefix: 'currency',

    },
    {
        key: 'tong_cac_khoan_tru_thu_nhap',
        title: 'Tổng các khoản trừ thu nhập (43)',
        tooltip: "(43) = (38) + (39) + (40) + (41) + (42)",
        prefix: 'currency',
    },
    {
        key: 'thu_viec',
        title: 'Thử việc'
    },
    {
        key: 'ghi_chu',
        title: 'Ghi chú'
    },
    {
        key: 'thu_nhap_thuc_nhan',
        title: 'Thu nhập thực nhận (44)',
        tooltip: "(44) = (37) - (43)\n" +
            "Trong thời gian thử việc: (44)  = 85% * [(37) - (43)]",
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
        prefix: 'currency'
    }
]
