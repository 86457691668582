<script>

import {formatDate, formatVNDNoPrefix} from "../../../core/filters";
import {GET_DETAIL_BOND_VALUE} from "../../../core/services/store/service.module";
import {NAME_STATUS_BOND} from "../../../core/config/payslipConfig";

export default {
  name: "trai-phieu-detail-item",
  methods: {
    formatVNDNoPrefix,
    formatDate,
    openModal() {
      this.dialogTableVisible = true
      this.loadingDetail = true;
      this.$store.dispatch(GET_DETAIL_BOND_VALUE).then((data) => {
        this.bondValue = data?.data?.map(e => {
          return {...e, open: false}
        })
      }).finally(() => {
        this.loadingDetail = false;
      })
    },
    isMobile() {
      return window.innerWidth <= 768; // Kích thước màn hình nhỏ hơn hoặc bằng 768px được coi là mobile
    }
  },
  components: {},
  data() {
    return {
      dialogTableVisible: false,
      bondValue: [],
      loadingDetail: false,
      NAME_STATUS_BOND: NAME_STATUS_BOND
    }
  },
}
</script>

<template>
  <div>
    <el-button type="text" @click="openModal">Xem chi tiết trái phiếu</el-button>
    <el-dialog title="Xem chi tiết trái phiếu" :visible.sync="dialogTableVisible" :width="isMobile()?'100vw': '80vw'">
      <div v-if="loadingDetail" class="text-center">
        <i class="el-icon-loading"></i>
      </div>
      <div style="overflow-y: auto; max-height: 500px;overflow-x: hidden" v-else>
        <!--start_item-->
        <div v-for="(bond) in bondValue" :key="bond">
          <div class="row">
            <h1 class="col-lg-2 col-md-12" style="font-size: 13px">Mốc tích lũy <span
                style="font-size: 20px;font-weight: bold">{{bond?.milestone}}</span></h1>
            <h1 style="font-size: 13px" class="col-lg-10 col-md-12">Giai đoạn <span
                style="font-size: 20px;font-weight: bold">{{NAME_STATUS_BOND[bond?.phase]}}</span>
            </h1>
          </div>
          <div class="mt-4 " :class="{'d-flex': !isMobile()}">
            <p class="col-lg-2 p-0 col-12">Thời điểm bắt đầu tích lũy:</p>
            <p class="col-lg-1 p-0 bold col-12">{{ formatDate(bond?.start_date) }}</p>
            <p class="col-lg-3 p-0 col-12" :class="{'ml-3': !isMobile()}">Thời điểm trái phiếu có hiệu lực:</p>
            <p class="col-lg-1 p-0 bold col-12">{{ formatDate(bond?.end_date) }}</p>
            <p class="col-lg-3 p-0 col-12" :class="{'ml-3': !isMobile()}">Thời điểm được chuyển nhượng/bán:</p>
            <p class="col-lg-1 p-0 bold col-12">{{ formatDate(bond?.allowed_sale_date) }}</p>
          </div>
          <div class=" mt-2 " :class="{'d-flex': !isMobile()}">
            <p class="col-lg-2 p-0 col-12">Tổng giá trị TP</p>
            <p class="col-lg-1 p-0 col-12">{{ formatVNDNoPrefix(bond?.amount_total) }}đ</p>
            <p class="col-lg-3 p-0 col-12" :class="{'ml-3': !isMobile()}">Số lượng TP đã bán/chuyển nhượng</p>
            <p class="col-lg-1 p-0 col-12">{{ formatVNDNoPrefix(bond?.amount_sold) }}đ</p>
            <p class="col-lg-3 p-0 bold col-12" :class="{'ml-3': !isMobile()}" style="font-size: 16px">Số lượng TP còn lại</p>
            <p class="col-lg-2 p-0 bold col-12" style="font-size: 20px">
              {{ formatVNDNoPrefix(bond?.amount_balance) }}đ</p>
          </div>
          <div>
            <h1 class="bold">Các khoản tích lũy theo từng năm <i v-if="!bond.open" class="el-icon-arrow-down"
                                                                 style="cursor: pointer}" @click="bond.open = true"></i>
              <i v-else class="el-icon-arrow-up" style="cursor: pointer" @click="bond.open = false"></i></h1>
          </div>
          <div class="mt-3" v-if="bond?.open">
            <div class="row mt-1" v-for="(item) in bond.items" :key="item">
              <p class="col-3">Năm {{ item?.year_number }}</p>
              <p>{{ formatVNDNoPrefix(item?.amount) }}đ</p>
            </div>
          </div>
          <hr style="border: none;border-top: 1px solid #AAAAAA;margin: 20px 0">
        </div>
      </div>
      <!--end_item-->

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
</style>