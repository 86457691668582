<template>
  <tr v-if="space_before_row">
    <td colspan="3" style="width: 500px">&nbsp;</td>
  </tr>
  <tr v-else :class="{className}">
    <td :class="tooltip ? 'pointer': ''" :title="tooltip" :rowSpan="rowSpan" v-if="!hideTitle" style="width: 500px">
      <span :class="title_class_name">{{ title }}<i v-if="tooltip"
                                                    class="fa fa-fw fa-info-circle text-primary"></i></span>
    </td>
    <td><b>{{ value }}</b></td>
    <td>
      <phieu-luong-detail-item
				v-if="details != null && details.length && [CATEGORY_THU_NHAP_RSM,CATEGORY_PHAT,CATEGORY_TRU_KHAC,].includes(category)"
				:details="details"
        :category="category"
			>
			</phieu-luong-detail-item>
      <trai-phieu-detail-item v-if="category === THUONG_TRAI_PHIEU_TICH_LUY && haveDataBond"></trai-phieu-detail-item>
    </td>
  </tr>
</template>

<script>

import PhieuLuongDetailItem from "./phieu-luong-detail-item";
import {
  CATEGORY_THU_NHAP_RSM,
  CATEGORY_PHAT,
  CATEGORY_TRU_KHAC, THUONG_TRAI_PHIEU_TICH_LUY
} from "../../../core/config/payslipConfig";
import TraiPhieuDetailItem from "./trai-phieu-detail-item.vue";
import {GET_DETAIL_BOND_VALUE} from "../../../core/services/store/service.module";

export default {
  name: 'phieu-luong-item',
  components: {TraiPhieuDetailItem, PhieuLuongDetailItem},
  props: {
    details: {
      type: Array,
      default: () => {
        return []
      },
    },
    category: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    title_class_name: {
      type: String,
      default: '',
    },
    space_before_row: {
      type: Boolean,
      default: false,
    },
    rowSpan: {
      type: Number,
    },
  },
	data(){
		return {
			CATEGORY_THU_NHAP_RSM : CATEGORY_THU_NHAP_RSM,
      CATEGORY_PHAT: CATEGORY_PHAT,
      CATEGORY_TRU_KHAC: CATEGORY_TRU_KHAC,
      THUONG_TRAI_PHIEU_TICH_LUY: THUONG_TRAI_PHIEU_TICH_LUY,
      haveDataBond: false
		}
	},
  methods: {
    checkDataBonds(){
      this.$store.dispatch(GET_DETAIL_BOND_VALUE).then((data) => {
        console.log(data?.data?.length)
        this.haveDataBond = data?.data?.length !== 0
      }).catch(() => {
        this.haveDataBond = false
      })
    }
  },
  mounted() {
    if(this.category === THUONG_TRAI_PHIEU_TICH_LUY){
      this.checkDataBonds()
    }
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
